<template>
  <div>
    <p
      id="service"
      class="enTitle"
    >COMPREHENSIVE DESIGN SERVICE</p>
    <p class="a1-1 a1 chTitle">全方位的設計服務</p>
    <div class="contentWrap">
      <p class="a3-1 a1">永恆國際專為有改造網頁需求的客戶提供最佳的方案，擁有免費體檢服務，讓客戶可以了解自己網頁缺點，針對缺點改造，打造成最符合您需求的網站！永恆同時搭配「年度全網多媒體行銷套餐」，除了網站架設以外，也為您進行網站內容的補充，優化網站在搜索引擎上的排名，不用投廣告也能增加自然搜尋流量除了網站架設也要了解行銷推廣推廣的重要性！</p>
    </div>
    <div
      id="in-page-one"
      class="in-page-one"
    >
      <div class="content">
        <div class="projectInfo">
          <div class="project">
            <img
              src="../assets/image/Explanation-01.png"
              alt=""
            >
            <div class="projectWrap">
              <div class="titleInfo">
                RWD Web
              </div>
              <div class="title">
                響應式網頁設計
              </div>
              <div class="projectContent">
                「響應式網站設計，全部適用」同一個網頁跨平台瀏覽自動適應網頁適合各種瀏覽器螢幕解析度。
              </div>
            </div>
          </div>
          <div class="project">
            <img
              src="../assets/image/Explanation-02.png"
              alt=""
            >
            <div class="projectWrap">
              <div class="titleInfo">
                Web Design
              </div>
              <div class="title">
                客製化、模組網站設計服務
              </div>
              <div class="projectContent">
                「企業形象.品牌商品」最佳表現，讓產品的真實感與企業經營研發商品的用心，表現真實淋漓盡致。
              </div>
            </div>
          </div>
          <div class="project">
            <img
              src="../assets/image/Explanation-03.png"
              alt=""
            >
            <div class="projectWrap">
              <div class="titleInfo">
                Backend Management
              </div>
              <div class="title">
                全站式後台管理
              </div>
              <div class="projectContent">
                我們提供完整且人性化的網站後台，讓您自行從後台更新維護網站內容。
              </div>
            </div>
          </div>
          <div class="project">
            <img
              src="../assets/image/Explanation-04.png"
              alt=""
            >
            <div class="projectWrap">
              <div class="titleInfo">
                SEO
              </div>
              <div class="title">
                搜尋引擎排名優化
              </div>
              <div class="projectContent">
                SEO優化服務幫您把網站排名飆到第一頁去，最便宜有效的行銷方式，最強大又持久的行銷媒體。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p
      id="seo"
      class="enTitle"
    >SEO KEYWORD MARKETING</p>
    <p class="a1-1 a1 chTitle">SEO關鍵字行銷</p>
    <div class="contentWrap">
      <p class="a3-1 a1">有了網站，但沒做SEO，就像是印好了商品宣傳單，卻沒人去發送一樣，我們不要客戶花了時間和金錢所做的網站，卻一點也沒有成效，所以我們直接幫您內建了關鍵字行銷的操作！透過自然排序的方式增加網站能見度的，進而提高網頁排名、流量以及增加網頁在搜尋引擎的曝光度，讓您的客戶輕易地找到您！</p>
    </div>
    <p
      id="template"
      class="enTitle"
    >TEMPLATE STYLE</p>
    <p class="a1-1 a1 chTitle">模板樣式</p>
    <div class="tempalteWrap">
      <img
         src="../assets/image/water-template.jpg"
         alt=""
         @click="openLink('http://water-warm.com.tw/')"
       >
      <img
        src="../assets/image/template.jpg"
        alt=""
        @click="openLink('01')"
      >
      <img
        src="../assets/image/template02.jpg"
        alt=""
        @click="openLink('02')"
      >
      <img
        src="../assets/image/template03.jpg"
        alt=""
        @click="openLink('03')"
      >
      <img
        src="../assets/image/template04.jpg"
        alt=""
        @click="openLink('04')"
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openLink (num) {
      const link = '' + num
      window.open(link)
    }
  }
}
</script>

<style scoped lang="scss">
@import "./home.scss";
</style>
